export default [
  {
    path: '/site',
    name: 'site',
    component: () => import('../pages/KurccSiteFormPage.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/branch/:branch_id',
    name: 'branch',
    component: () => import('../pages/KurccSiteBranchPage.vue'),
    props: true,
    meta: {
      auth: undefined
    }
  }
]
