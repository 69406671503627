<template>
  <v-btn class="center-center" icon x-large
         @click="$attrs.trigger">
    <v-icon>mdi-reload</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'KurccInfiniteLoadingReloadButton'
}
</script>
