export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../pages/KurccDashboardPage.vue'),
    redirect: { name: 'profile' },
    meta: {
      auth: true
    },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../pages/KurccProfilePage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'analytics',
        name: 'analytics',
        component: () => import('../pages/KurccAnalyticsPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'branches',
        name: 'branches',
        component: () => import('../pages/KurccBranchesPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'inspections',
        name: 'inspections',
        component: () => import('../pages/KurccInspectionsPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'gallery',
        name: 'gallery',
        component: () => import('../pages/KurccGalleryPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'favorites',
        name: 'favorites',
        component: () => import('../pages/KurccFavoritesPage.vue'),
        meta: {
          auth: true
        }
      }
    ]
  }
]
