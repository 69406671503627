import Vue from 'vue'
import Inspection from '@/modules/app/models/inspection'
import * as actionHelper from '@/modules/app/helpers/actions'

export default {
  getAllInspections (store, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'inspection', ['site', 'branch', 'inspector', 'group'])
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Inspection.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  }
}
