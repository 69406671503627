export default [
  {
    path: '/home',
    name: 'home',
    redirect: { name: 'main' },
    component: () => import('../pages/KurccHomePage.vue'),
    meta: {
      auth: undefined
    },
    children: [
      {
        path: '/main',
        name: 'main',
        component: () => import('../pages/KurccHomePageMain.vue'),
        meta: {
          auth: undefined
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../pages/KurccHomePageSearchResult.vue'),
        meta: {
          auth: undefined
        }
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../pages/KurccHomePagePrivacyPolicy.vue'),
        meta: {
          auth: undefined
        }
      },
      {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('../pages/KurccHomePageTOS.vue'),
        meta: {
          auth: undefined
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../pages/KurccHomePageContactUs.vue'),
        meta: {
          auth: undefined
        }
      }
    ]
  }
]
