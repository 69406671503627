import bearer from '@/modules/auth/bearer'
import httpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm'
import routerVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm'
import Staff from '@/modules/app/models/staff'

export default {
  auth: bearer,
  http: httpAxios,
  router: routerVueRouter,
  registerData: {
    url: 'register',
    method: 'POST',
    redirect: 'main',
    autoLogin: true,
    fetchUser: true,
    staySignedIn: true
  },
  loginData: {
    url: '/clients/web/admin/login',
    method: 'POST',
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-type': 'application/json',
    },
    redirect: { name: 'home' },
    fetchUser: true
  },
  logoutData: {
    url: '/logout',
    method: 'DELETE',
    redirect: { name: 'main' },
    makeRequest: true
  },
  fetchData: {
    url: '/profile?include=site',
    method: 'GET',
    enabled: true
  },
  refreshData: {
    url: '/clients/web/admin/refresh',
    method: 'POST',
    enabled: false,
    interval: 30,
    data: { refresh_token: localStorage.getItem('refresh_token') }
  },
  stores: ['storage', 'cookie'],
  tokenDefaultKey: 'access_token',
  authRedirect: { name: 'login' },
  parseUserData: function (data) {
    return Staff.fromJson(data.data)
  }
  // rolesKey: 'roles.name',
}
