export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/modules/auth/pages/KurccLoginPage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('@/modules/auth/pages/KurccRegisterPage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/modules/auth/pages/KurccForgotPasswordPage'),
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('@/modules/auth/pages/KurccResetPasswordPage'),
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/403',
    name: 'forbidden',
    component: () => import('@/modules/auth/pages/KurccForbiddenPage')
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('@/modules/auth/pages/KurccNotFoundPage')
  }
]
