import { en } from 'vuetify/es5/locale'

export default {
  ...en,

  general: {
    becomeAVendor: 'Become a Vendor',
    navItems: {
      sites: 'Sites',
      contactUs: 'Contact Us',
      about: 'About'
    },
    footer: {
      menu: 'Menu',
      contact: 'Contact',
      termsAndConditions: 'Terms and conditions',
      privacy: 'Privacy'
    },
    backToTop: 'Back to top',
    searchInAllSites: 'Search in all sites',
    perPage: 'Items per page'
  },
  pages: {
    home: {
      drawerMenu: {
        profile: 'Profile',
        analytics: 'Analytics',
        branches: 'Branches',
        gallery: 'Gallery',
        inspections: 'Inspections',
        favorites: 'Favorites',
        signOut: 'Sign out'
      },
      topGradeSites: 'Top grade sites',
      topRatedSites: 'Top rated sites',
      recentSites: 'Recent sites',
      carouselTitle: 'Kuwait food index',
      carouselText: 'Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        'food to ensure restaurants and other food retail outlets',
      contactUsPromo: {
        title: 'What services do we provide?',
        subtitle: 'We inspect and give grades to restaurants.So they can try their best to give top grades',
        btnText: 'Contact Us'
      },
      submitSitePromo: {
        title: 'Submit your restaurant to our list!',
        subtitle: 'Best for active freelancers and designers who need more projects and premium features.'
      }
    },
    dashboard: {
      profile: {
        revertChangesBtn: 'Revert Changes',
        saveChangesBtn: 'Save Changes'
      },
      branches: {
        deleteBranchMessage: 'Are you sure you want to delete this branch?',
        editBranch: 'Edit Branch',
        addBranch: 'Add Branch',
        branchList: 'Branch List',
        tableColumns: {
          branchNumber: 'Branch Number',
          brandName: 'Brand Name',
          address: 'Address',
          managerName: 'Manager Name',
          managerPhoneNumber: 'Manager Phone Number',
          createdAt: 'Created At',
          actions: 'Actions'
        }
      },
      gallery: {
        cover: 'Cover',
        logo: 'Logo',
        updateBtn: 'Update'
      },
      inspections: {
        inspectionList: 'Inspection List',
        tableColumns: {
          inspectionSite: 'Inspection Site',
          Group: 'Group',
          dueDate: 'Due Date',
          inspector: 'Inspector',
          address: 'Address',
          deadline: 'Deadline (Days)',
          score: 'Score',
          grade: 'Grade',
          type: 'Type',
          actions: 'Actions'
        }
      }
    },
    search: {
      results: 'results',
      sortAndFilter: {
        filter: 'Filter',
        filterByCategories: 'Filter by categories',
        categories: 'Categories',
        filterByBrand: 'Filter by brand',
        brand: 'Brands',
        filterByArea: 'Filter by area',
        areas: 'Areas',
        filterByGrade: 'Filter by grade',
        grades: 'Grades',
        clearFilterBtn: 'Clear Filter',
        sortBy: 'Sort by',
        sortByName: 'Sort by name',
        sortByRates: 'Sort by rates',
        highToLow: 'High to Low',
        lowToHigh: 'Low to High'
      }
    },
    site: {
      grade: 'Grade',
      contact: 'Contact',
      siteInfo: 'Site Info',
      category: 'Category',
      brandName: 'Brand name',
      userRating: 'User rating',
      branchLocations: 'Branch locations',
      form: {
        titles: {
          generalInfo: 'General Information',
          paci: 'PACI',
          commercialLicense: 'Commercial license',
          municipality: 'Municipality',
          managingPartner: 'Managing partner',
          seniorStaff: 'Senior staff',
          logo: 'LOGO',
          mainBranch: 'Main Branch',
          finalizeAndSubmit: 'Finalize and Submit'
        },
        fields: {
          siteName: 'Site Name',
          email: 'Email',
          companyNameEn: 'Company Name (EN)',
          companyNameAr: 'Company Name (AR)',
          category: 'Category',
          paciNumber: 'PACI Number',
          name: 'Name',
          phoneNumber: 'Phone Number',
          position: 'Position',
          logo: 'Logo',
          branchNumber: 'Branch Number',
          branchNameEn: 'Branch Name (EN)',
          branchNameAr: 'Branch Name (AR)',
          area: 'Area',
          address: 'Address',
          managerName: 'Manager Name',
          managerPhoneNumber: 'Manager Phone Number',
          licenseExpireDate: 'License Expire Date',
          licensePhoto: 'License Photo',
          finalMessage1: 'Before you submit, please review your site information and make sure it is correct.',
          finalMessage2: 'You can edit your site information again in your user dashboard before requesting it to be verified.'
        }
      }
    },
    termOfService: {
      title: 'Terms of service'
    },
    privacyAndPolicy: {
      title: 'Privacy & policy',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\n' +
        '            dolore\n' +
        '            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n' +
        '            commodo\n' +
        '            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla\n' +
        '            pariatur.\n' +
        '            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '            laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses\n' +
        '            serving\n' +
        '            food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '            elit,\n' +
        '            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n' +
        '            enim\n' +
        '            ad\n' +
        '            minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n' +
        '            enim\n' +
        '            ad\n' +
        '            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute\n' +
        '            irure\n' +
        '            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint\n' +
        '            occaecat\n' +
        '            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Restaurant Inspections\n' +
        '            In\n' +
        '            Your Area. Local public health departments regularly inspect businesses serving food to ensure restaurants\n' +
        '            and\n' +
        '            other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor\n' +
        '            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur\n' +
        '            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n' +
        '            veniamLorem\n' +
        '            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet,\n' +
        '            consectetur\n' +
        '            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\n' +
        '            quis\n' +
        '            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in\n' +
        '            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat\n' +
        '            cupidatat\n' +
        '            non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Restaurant Inspections In Your\n' +
        '            Area.\n' +
        '            Local public health departments regularly inspect businesses serving food to ensure restaurants and other\n' +
        '            food\n' +
        '            retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n' +
        '            labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '            elit,\n' +
        '            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamLorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor'
    },
    auth: {
      email: 'Email',
      password: 'password',
      rememberMe: 'Remember me',
      login: 'Login',
      forgotPassword: 'Forgot password',
      noAccount: 'Don’t have an account?',
      registerNow: 'Register now',
      registrationSteps: 'Registration steps',
      faqs: 'Frequently asked questions',
      homePage: 'Home page',
      forbiddenMessage: 'You entered a forbidden place!',
      home: 'Home',
      remindPassword: 'Remind password',
      sendMail: 'Send Email',
      takeMeBack: 'Take me back to',
      register: 'Register',
      haveAccount: 'Have an account?',
      resetPassword: 'Reset password',
      newPassword: 'New Password',
      introTitle: 'Login to KFI',
      introMessage: 'Get grades and rates for your Food business',
      contactSupport: 'Contact KFI vendor center'
    },
    contactUs: {
      title: 'Contact KFI',
      subtitle: 'Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses\n' +
        'serving food to ensure restaurants and other food retail outlets.',
      dropALine: 'Drop us a line',
      name: 'Name',
      message: 'Message',
      contactBtn: 'Contact Us',
      headOfficeInfo: 'Head office info'
    },
    about: {
      title: 'Best way to improve your food business',
      subtitle: '          Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '          food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum\n' +
        '          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '          aliqua. Ut enim ad minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor\n' +
        '          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      firstSection: '      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit\n' +
        '        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      secondSection: '      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit\n' +
        '        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
    }
  },
  languageSelectorOptions: {
    english: 'English',
    arabic: 'Arabic'
  }
}
