import { ar } from 'vuetify/es5/locale'

export default {
  ...ar,

  general: {
    becomeAVendor: 'Become a Vendor',
    navItems: {
      sites: 'المواقع',
      contactUs: 'تواصل معنا',
      about: 'About'
    },
    footer: {
      menu: 'قائمة',
      contact: 'التواصل',
      termsAndConditions: 'الشروط و الاحكام',
      privacy: 'سرية'
    },
    backToTop: 'الذهاب لاعلي الصفحة',
    searchInAllSites: 'بحث شامل',
    perPage: 'الأصناف بالصفحة'
  },
  pages: {
    home: {
      drawerMenu: {
        profile: 'الملف المستخدم',
        analytics: 'تحاليل',
        branches: 'الافرع',
        gallery: 'الاستوديو',
        inspections: 'التفتيشات',
        favorites: 'Favorites',
        signOut: 'تسجيل الخروج'
      },
      topGradeSites: 'المواقع الأعلى تقييما',
      topRatedSites: 'المواقع الأعلى تصنيفا',
      recentSites: 'أحدث المواقع',
      carouselTitle: 'Kuwait food index',
      carouselText: 'Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        'food to ensure restaurants and other food retail outlets',
      contactUsPromo: {
        title: 'الخدمات التي نقدمها',
        subtitle: 'نقيم المطاعم لترتقي جودة المنتج للمستهلك',
        btnText: 'تواصل معنا'
      },
      submitSitePromo: {
        title: 'Submit your restaurant to our list!',
        subtitle: 'Best for active freelancers and designers who need more projects and premium features.'
      }
    },
    dashboard: {
      profile: {
        revertChangesBtn: 'Revert Changes',
        saveChangesBtn: 'حفظ التعديلات'
      },
      branches: {
        deleteBranchMessage: 'متاکچ تریچ مسچ الفرع؟',
        editBranch: 'تعچیل االفرع',
        addBranch: 'اضافة الفرع',
        branchList: 'قائمة الافرع',
        tableColumns: {
          branchNumber: 'اسم االفرع',
          brandName: 'اسم الماركة',
          address: 'العنوان',
          managerName: 'اسم المدير',
          managerPhoneNumber: 'رقم هاتف المدير',
          createdAt: 'تم الانشاء في',
          actions: 'اجراءات'
        }
      },
      gallery: {
        cover: 'تغطية',
        logo: 'شعار',
        updateBtn: 'تحديث'
      },
      inspections: {
        inspectionList: 'قائمة التفاتيش',
        tableColumns: {
          inspectionSite: 'الموقع المعني بالتفتيش',
          Group: 'المجموعة',
          dueDate: 'Due Date',
          inspector: 'المفتش',
          address: 'العنوان',
          deadline: 'Deadline (Days)',
          score: 'الناتج',
          grade: 'التقييم',
          type: 'نوع',
          actions: 'اجراءات'
        }
      }
    },
    search: {
      results: 'نتائج',
      sortAndFilter: {
        filter: 'فلتر',
        filterByCategories: 'فلتر بالتصنيف',
        categories: 'اصناف',
        filterByBrand: 'فلتر بالعلامة التجارية',
        brand: 'Brands',
        filterByArea: 'فلتر بالمنطقة',
        areas: 'Areas',
        filterByGrade: 'فلتر بالتقييم',
        grades: 'تقييمات',
        clearFilterBtn: 'إزالة الفلتر',
        sortBy: 'ترتيب حسب',
        sortByName: 'ترتيب حسب بالاسم',
        sortByRates: 'ترتيب حسب التقييم',
        highToLow: 'من الأعلى الى الأقل',
        lowToHigh: 'من الأقل إلى الأعلى'
      }
    },
    site: {
      grade: 'التقييم',
      contact: 'التواصل',
      siteInfo: 'معلومات الموقع',
      category: 'صنف',
      brandName: 'Brand name',
      userRating: 'تصنيف المستخدمين',
      branchLocations: 'موقع الفرع',
      form: {
        titles: {
          generalInfo: 'معلومات عامة',
          paci: 'المعلومات المدنية',
          commercialLicense: 'رخصة تجارية',
          municipality: 'البلدية',
          managingPartner: 'شريك إداري',
          seniorStaff: 'الموظف المسؤول',
          logo: 'شعار',
          mainBranch: 'الفرع الاصلي',
          finalizeAndSubmit: 'أودع'
        },
        fields: {
          siteName: 'اسم الموقع',
          email: 'ايميل',
          companyNameEn: 'اسم الشركة (EN)',
          companyNameAr: 'اسم الشركة (AR)',
          category: 'صنف',
          paciNumber: 'رقم المعلومات المدنية',
          name: 'اسم',
          phoneNumber: 'رقم هاتف',
          position: 'الصفة',
          logo: 'شعار',
          branchNumber: 'رقم الفرع',
          branchNameEn: 'Branch Name (EN)',
          branchNameAr: 'اسم الفرع (AR)',
          area: 'المنطقة',
          address: 'العنوان',
          managerName: 'اسم المدير',
          managerPhoneNumber: 'رقم هاتف مدير',
          licenseExpireDate: 'تاريخ انتهاء الترخيص',
          licensePhoto: 'صورة الترخيص',
          finalMessage1: 'الرجاء التأكد من المعلومات قبل الإيداع',
          finalMessage2: 'يمكن تعديل المعلومات عن طريق لوحة التحكم قبل طلب التأكيد'
        }
      }
    },
    termOfService: {
      title: 'الشروط و الأحكام'
    },
    privacyAndPolicy: {
      title: 'سياسة الخصوصية',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\n' +
        '            dolore\n' +
        '            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n' +
        '            commodo\n' +
        '            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla\n' +
        '            pariatur.\n' +
        '            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '            laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses\n' +
        '            serving\n' +
        '            food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '            elit,\n' +
        '            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n' +
        '            enim\n' +
        '            ad\n' +
        '            minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n' +
        '            enim\n' +
        '            ad\n' +
        '            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute\n' +
        '            irure\n' +
        '            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint\n' +
        '            occaecat\n' +
        '            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Restaurant Inspections\n' +
        '            In\n' +
        '            Your Area. Local public health departments regularly inspect businesses serving food to ensure restaurants\n' +
        '            and\n' +
        '            other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor\n' +
        '            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur\n' +
        '            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n' +
        '            veniamLorem\n' +
        '            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet,\n' +
        '            consectetur\n' +
        '            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\n' +
        '            quis\n' +
        '            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in\n' +
        '            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat\n' +
        '            cupidatat\n' +
        '            non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Restaurant Inspections In Your\n' +
        '            Area.\n' +
        '            Local public health departments regularly inspect businesses serving food to ensure restaurants and other\n' +
        '            food\n' +
        '            retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n' +
        '            labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '            elit,\n' +
        '            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamLorem ipsum dolor\n' +
        '            sit\n' +
        '            amet, consectetur adipiscing elit, sed do eiusmod tempor'
    },
    auth: {
      email: 'ايميل',
      password: 'كلمة المرور',
      rememberMe: 'تذكرني',
      login: 'تسجيل الدخول',
      forgotPassword: 'نسيت كلمة المرور؟',
      noAccount: 'لا تملك حساب؟',
      registerNow: 'سجل الآن',
      registrationSteps: 'خطوات التسجيل',
      faqs: 'الأسئلة الشائعة',
      homePage: 'الصفحة الرئيسية',
      forbiddenMessage: 'تم إدخال في الخانة الخطأ',
      home: 'الرئيسية',
      remindPassword: 'Remind password',
      sendMail: 'ارسال ايميل',
      takeMeBack: 'أرجعني الي',
      register: 'التسجيل',
      haveAccount: 'هل لديك حساب؟',
      resetPassword: 'اعادة اعييين كلمة المرور',
      newPassword: 'كلمة مرور جديد',
      introTitle: 'تسجيل الدخول الي مؤشر غذاء الكويتي',
      introMessage: 'احصل على تصنيف لمشروعك الغذائي',
      contactSupport: 'تواصل مع مركز التصنيفات الكويتي'
    },
    contactUs: {
      title: 'Contact KFI',
      subtitle: 'Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses\n' +
        'serving food to ensure restaurants and other food retail outlets.',
      dropALine: 'Drop us a line',
      name: 'الاسم',
      message: 'رسالة',
      contactBtn: 'تواصل معنا',
      headOfficeInfo: 'معلومات المكتب الرئيسي'
    },
    about: {
      title: 'أفضل طريقة لتحسين مشروعك الغذائي',
      subtitle: '          Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '          food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
        '          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum\n' +
        '          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
        '          aliqua. Ut enim ad minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor\n' +
        '          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      firstSection: '      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit\n' +
        '        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      secondSection: '      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit\n' +
        '        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
        '      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
        '      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n' +
        '      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est\n' +
        '      laborum.Restaurant Inspections In Your Area. Local public health departments regularly inspect businesses serving\n' +
        '      food to ensure restaurants and other food retail outlets. Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
        '      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit\n' +
        '      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad\n' +
        '      minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
    }
  },
  languageSelectorOptions: {
    english: 'English',
    arabic: 'العربية'
  }
}
