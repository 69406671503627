import Vue from 'vue'
import * as actionHelper from '@/modules/app/helpers/actions'
import Category from '@/modules/app/models/category'
import Site from '@/modules/app/models/site'

export default {
  getAllCategories (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'category', 'groups')
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Category.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  getAllSites (context, payload) {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL(payload, 'site', ['category', 'inspections'])
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Site.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  getAllConfigs () {
    return new Promise((resolve, reject) => {
      const url = actionHelper.prepareGetAllURL({}, 'configuration')
      return Vue.axios.get(url)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  }
}
