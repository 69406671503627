import actions from '@/modules/dashboard/store/actions'
import getters from '@/modules/dashboard/store/getters'
import mutations from '@/modules/dashboard/store/mutations'

export default {
  state: () => ({}),
  actions: actions,
  getters: getters,
  mutations: mutations
}
