import dayjs from 'dayjs'
import lodash from 'lodash'
import Role from '@/modules/app/models/role'
import Site from '@/modules/app/models/site'

export default class Staff {
  constructor (
    id = null,
    firstName = 'Default first name',
    lastName = 'Default last name',
    email = '',
    object = '',
    phoneNumber = '',
    staffId = '',
    role = null,
    site = null,
    rolesNames = '',
    enabled = 1,
    confirmed = false,
    idsOfGroupsStaffIsAdminIn = [],
    groupsCount = -1,
    createdAt = ''
  ) {
    this.id = id
    this.first_name = firstName
    this.last_name = lastName
    this.email = email
    this.object = object
    this.phone_number = phoneNumber
    this.staff_id = staffId
    this.role = role
    this.site = site
    this.roles_names = rolesNames
    this.enabled = enabled
    this.confirmed = confirmed
    this.ids_of_groups_staff_is_admin_in = idsOfGroupsStaffIsAdminIn
    this.groups_count = groupsCount
    this.created_at = createdAt
  }

  static fromJson (json) {
    return new Staff(
      json.id,
      json.first_name,
      json.last_name,
      json.email,
      json.object,
      json.phone_number,
      json.staff_id,
      json.roles?.data.length > 0 ? Role.fromJson(json.roles.data[0]) : null,
      json.site?.data.id ? Site.fromJson(json.site.data) : null,
      Staff.getRoleNames(json),
      json.enabled,
      json.confirmed,
      json.ids_of_groups_staff_is_admin_in,
      json.groups_count,
      dayjs(json.created_at).format('YYYY-MM-DD'))
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(staff => {
      result.push(this.fromJson(staff))
    })
    return result
  }

  static getRoleNames (json) {
    const roleNames = []
    if (json.roles?.data.length > 0) {
      json.roles.data.forEach(role => {
        roleNames.push(role.display_name)
      })
    }
    return roleNames.join()
  }

  isAdminInGroup (groupId) {
    return lodash.find(this.ids_of_groups_staff_is_admin_in, id => id === groupId) !== undefined
  }
}
