import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import InfiniteLoadingReloadButton from '@/modules/app/components/KurccInfiniteLoadingReloadButton'

Vue.use(InfiniteLoading, {
  slots: {
    noMore: 'No more data',
    error: InfiniteLoadingReloadButton
  }
})
