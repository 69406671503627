export default [
  {
    path: '/about',
    name: 'about',
    component: () => import('../pages/KurccAboutPage.vue'),
    meta: {
      auth: undefined
    }
  }
]
