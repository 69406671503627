import axios from 'axios'
import store from '@/modules/app/store'
import { SHOW_SNACKBAR_ERROR, SHOW_SNACKBAR_SUCCESS } from '@/modules/app/store/mutation-types'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  switch (response.status) {
    case 200:
      if (response.config.method === 'patch') {
        callSnackbarSuccess('Item Updated')
      }
      break
    case 201:
      callSnackbarSuccess('Item Created')
      break
    case 204:
      if (response.config.method === 'delete') {
        callSnackbarSuccess('Item Deleted')
      }
      break
  }
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (typeof error.response === 'undefined') {
    callSnackbarError('A network error occurred')
  } else {
    switch (error.response.status) {
      case 401:
        // callSnackbarError('Authorization failed')
        break
      case 404:
        callSnackbarError('Resource not found')
        break
      case 422:
        if (error.response.data.errors.email) {
          callSnackbarError(error.response.data.errors.email[0])
        } else {
          callSnackbarError('Please check your inputs')
        }
        break
      default:
        callSnackbarError('Something went wrong!')
    }
  }
  return Promise.reject(error)
})

const callSnackbarSuccess = (msg) => {
  store.commit(SHOW_SNACKBAR_SUCCESS, msg)
}

const callSnackbarError = (msg) => {
  store.commit(SHOW_SNACKBAR_ERROR, msg)
}

export default axios
