import dayjs from 'dayjs'
import Category from '@/modules/app/models/category'

export default class Site {
  constructor (
    id = null,
    userId = -1,
    name = undefined,
    object = '',
    email = '',
    companyNameEn = '',
    companyNameAr = '',
    enabled = 1,
    managingPartnerName = '',
    managingPartnerEmail = '',
    managingPartnerPhoneNumber = '',
    seniorStaffPosition = '',
    seniorStaffName = '',
    seniorStaffEmail = '',
    seniorStaffPhoneNumber = '',
    createdAt = '',
    category = undefined,
    paciLicenseNumber = '',
    paciLicenseExpireDate = '',
    paciLicensePhoto = null,
    commercialLicenseNumber = '',
    commercialLicenseExpireDate = '',
    commercialLicensePhoto = null,
    municipalityLicenseExpireDate = '',
    municipalityLicensePhoto = null,
    logo = null,
    cover = null,
    branchesCount = 0
  ) {
    this.id = id
    this.user_id = userId
    this.name = name
    this.object = object
    this.email = email
    this.company_name_en = companyNameEn
    this.company_name_ar = companyNameAr
    this.enabled = enabled
    this.managing_partner_name = managingPartnerName
    this.managing_partner_email = managingPartnerEmail
    this.managing_partner_phone_number = managingPartnerPhoneNumber
    this.senior_staff_position = seniorStaffPosition
    this.senior_staff_name = seniorStaffName
    this.senior_staff_email = seniorStaffEmail
    this.senior_staff_phone_number = seniorStaffPhoneNumber
    this.created_at = createdAt
    this.category = category
    this.paci_license_number = paciLicenseNumber
    this.paci_license_expire_date = paciLicenseExpireDate
    this.paci_license_photo = paciLicensePhoto
    this.commercial_license_number = commercialLicenseNumber
    this.commercial_license_expire_date = commercialLicenseExpireDate
    this.commercial_license_photo = commercialLicensePhoto
    this.municipality_license_expire_date = municipalityLicenseExpireDate
    this.municipality_license_photo = municipalityLicensePhoto
    this.logo = logo
    this.cover = cover
    this.branches_count = branchesCount
  }

  static fromJson (json) {
    return new Site(
      json.id,
      json.user_id,
      json.name,
      json.object,
      json.email,
      json.company_name_en,
      json.company_name_ar,
      json.enabled,
      json.managing_partner_name,
      json.managing_partner_email,
      json.managing_partner_phone_number,
      json.senior_staff_position,
      json.senior_staff_name,
      json.senior_staff_email,
      json.senior_staff_phone_number,
      dayjs(json.created_at).format('YYYY-MM-DD'),
      json.category ? Category.fromJson(json.category.data) : null,
      json.paci_license_number,
      dayjs(json.paci_license_expire_date).format('YYYY-MM-DD'),
      json.paci_license_photo?.original,
      json.commercial_license_number,
      dayjs(json.commercial_license_expire_date).format('YYYY-MM-DD'),
      json.commercial_license_photo?.original,
      dayjs(json.municipality_license_expire_date).format('YYYY-MM-DD'),
      json.municipality_license_photo?.original,
      json.logo?.original,
      json.cover?.original,
      json.branches_count
    )
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(site => {
      result.push(this.fromJson(site))
    })
    return result
  }
}
