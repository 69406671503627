export default class Category {
  constructor (
    id = null,
    name = 'Default Category',
    routineInspectionDurationDays = null,
    deadlineDays = null,
    enabled = 1,
    object = ''
  ) {
    this.id = id
    this.name = name
    this.routine_inspection_duration_days = routineInspectionDurationDays
    this.deadline_days = deadlineDays
    this.enabled = enabled
    this.object = object
  }

  static fromJson (json) {
    return new Category(
      json.id,
      json.name,
      json.routine_inspection_duration_days,
      json.deadline_days,
      json.enabled,
      json.object
    )
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(category => {
      result.push(this.fromJson(category))
    })
    return result
  }
}
