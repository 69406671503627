// App
export const SET_SNACKBAR_DISPLAY_STATE = 'SET_SNACKBAR_DISPLAY_STATE'
export const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE'
export const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR'
export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS'
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING'
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
