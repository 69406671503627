import dayjs from 'dayjs'
import Site from '@/modules/app/models/site'
import Inspection from '@/modules/app/models/inspection'

export default class Branch {
  constructor (
    id = null,
    number = '',
    object = '',
    brandNameEn = '',
    brandNameAr = '',
    area = '',
    address = '',
    fullAddress = '',
    managerName = '',
    managerPhoneNumber = '',
    site = null,
    lastDoneInspection = null,
    likeStatus = null,
    isFavorited = null,
    likesCount = null,
    dislikesCount = null,
    favoritesCount = null,
    createdAt = ''
  ) {
    this.id = id
    this.number = number
    this.object = object
    this.brand_name_en = brandNameEn
    this.brand_name_ar = brandNameAr
    this.area = area
    this.address = address
    this.full_address = fullAddress
    this.manager_name = managerName
    this.manager_phone_number = managerPhoneNumber
    this.site = site
    this.last_done_inspection = lastDoneInspection
    this.like_status = likeStatus
    this.is_favorited = isFavorited
    this.likes_count = likesCount
    this.dislikes_count = dislikesCount
    this.favorites_count = favoritesCount
    this.created_at = createdAt
  }

  static fromJson (json) {
    return new Branch(
      json.id,
      json.number,
      json.object,
      json.brand_name_en,
      json.brand_name_ar,
      json.area,
      json.address,
      json.area + ', ' + json.address,
      json.manager_name,
      json.manager_phone_number,
      json.site ? Site.fromJson(json.site.data) : null,
      json.last_done_inspection ? Inspection.fromJson(json.last_done_inspection) : null,
      json.like_status,
      json.is_favorited,
      json.likes_count,
      json.dislikes_count,
      json.favorites_count,
      dayjs(json.created_at).format('YYYY-MM-DD'))
  }

  static fromJsonArray (json) {
    const result = []
    json.forEach(branch => {
      result.push(this.fromJson(branch))
    })
    return result
  }
}
