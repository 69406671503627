import Vue from 'vue'
import { SHOW_SNACKBAR_ERROR } from '@/modules/app/store/mutation-types'

export default {
  login ({
    getters,
    commit
  }, payload) {
    payload = payload || {}
    return new Promise((resolve, reject) => {
      Vue.auth.login({
        data: payload,
        staySignedIn: payload.staySignedIn
      }).then((res) => {
        resolve(res)
      }).catch(err => {
        if (err.response.status === 403) {
          commit(SHOW_SNACKBAR_ERROR, err.response.data.message)
        }
        console.log(err)
        reject(err)
      })
    })
  },
  register ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.auth.register({
        data: payload,
        staySignedIn: payload.staySignedIn
      }).then((res) => {
        resolve(res)
      }).catch(err => {
        if (err.response.status === 403) {
          commit(SHOW_SNACKBAR_ERROR, err.response.data.message)
        }
        console.log(err)
        reject(err)
      })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      Vue.auth.logout().then((res) => {
        localStorage.removeItem('refresh_token')
        resolve(res)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  forgotPassword (context, payload) {
    const params = new URLSearchParams()
    params.set('email', payload.email)
    // TODO #160
    params.set('reseturl', 'password-reset')

    return new Promise((resolve, reject) => {
      Vue.axios.post('/password/forgot', params).then(res => {
        resolve(res)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  resetPassword (context, payload) {
    const params = new URLSearchParams()
    params.set('email', payload.email)
    params.set('token', payload.token)
    params.set('password', payload.password)

    return new Promise((resolve, reject) => {
      Vue.axios.post('/password/reset', params).then(res => {
        resolve(res)
        Vue.router.push({ name: 'login' })
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }
}
