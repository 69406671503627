<template>
  <v-app v-if="$auth.ready()" id="app">
    <kurcc-app-bar v-if="showAppbarAndFooter"/>
    <v-main>
      <v-container class="align-start fill-height pa-0" fluid>
        <v-container v-if="showNavbar && showAppbarAndFooter" class="pa-0 px-lg-16 my-5" fluid>
          <v-row align="start" align-md="center" justify="end" justify-lg="start" justify-md="space-between" no-gutters>
            <v-col v-if="displayPageButtons" class="text-start ms-5 my-10">
              <kurcc-home-page-nav-menu/>
            </v-col>
            <v-col cols="6" md="3" offset-xl="1" sm="4">
              <kurcc-brand/>
            </v-col>
            <v-col v-if="!displayPageButtons" class="text-start" xl="4">
              <kurcc-home-page-nav-buttons/>
            </v-col>
            <v-col cols="12" md="3" xl="2">
              <kurcc-search-box class="ma-5"/>
            </v-col>
          </v-row>
        </v-container>
        <router-view/>
      </v-container>
    </v-main>
    <kurcc-snackbar/>
    <kurcc-footer v-if="showAppbarAndFooter"/>
  </v-app>
  <v-app v-else id="app">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate/>
        </v-row>
      </v-container>
    </v-main>
    <kurcc-snackbar/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    KurccSnackbar: () => import('@/modules/app/components/KurccSnackbar'),
    KurccAppBar: () => import('@/modules/app/components/KurccAppBar'),
    KurccFooter: () => import('@/modules/app/components/KurccFooter'),
    KurccBrand: () => import('@/modules/app/components/KurccBrand'),
    KurccSearchBox: () => import('@/modules/app/components/KurccSearchBox'),
    KurccHomePageNavMenu: () => import('@/modules/app/components/KurccHomePageNavMenu'),
    KurccHomePageNavButtons: () => import('@/modules/app/components/KurccHomePageNavButtons')
  },
  computed: {
    isLoginPage () {
      return this.$route.name === 'login'
    },
    isRegisterPage () {
      return this.$route.name === 'register'
    },
    isForgotPasswordPage () {
      return this.$route.name === 'forgot-password'
    },
    isResetPasswordPage () {
      return this.$route.name === 'reset-password'
    },
    is404Page () {
      return this.$route.name === 'not-found'
    },
    is403Page () {
      return this.$route.name === 'forbidden'
    },
    isDashboardPage () {
      return this.$route.path.includes('/dashboard')
    },
    isSitePage () {
      return this.$route.name === 'site'
    },
    showAppbarAndFooter () {
      return !this.isLoginPage && !this.isRegisterPage && !this.isForgotPasswordPage && !this.isResetPasswordPage && !this.is403Page && !this.is404Page
    },
    displayPageButtons () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        default:
          return false
      }
    },
    showNavbar () {
      return !this.isDashboardPage && !this.isSitePage
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.center-center {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin-left: 0 !important;
}
</style>
