import Vue from 'vue'
import * as actionHelper from '@/modules/app/helpers/actions'
import Site from '@/modules/app/models/site'
import Branch from '@/modules/app/models/branch'

export default {
  createSite (store, payload) {
    const params = actionHelper.formDataFromProperties(payload,
      { category_id: payload.category?.id },
      ['category', 'user_id', 'enabled', 'branches_count'])

    return Vue.axios.post('/site', params)
      .then(res => {
        Vue.auth.user().site = Site.fromJson(res.data.data)
      })
  },
  updateSite (store, payload) {
    const params = actionHelper.formDataFromProperties(payload,
      { category_id: payload.category?.id },
      ['category', 'user_id', 'logo', 'paci_license_photo', 'commercial_license_photo', 'municipality_license_photo', 'cover'])

    setImageIfValid(payload, 'logo', params)
    setImageIfValid(payload, 'paci_license_photo', params)
    setImageIfValid(payload, 'commercial_license_photo', params)
    setImageIfValid(payload, 'municipality_license_photo', params)

    params.set('_method', 'patch')
    return Vue.axios.post('/site/' + payload.id, params)
      .then(res => {
        Vue.auth.user().site = Site.fromJson(res.data.data)
      })
  },
  updateSitePhoto (store, payload) {
    const params = actionHelper.formDataFromProperties(payload, {}, ['logo', 'cover'], [])

    setImageIfValid(payload, 'cover', params)
    setImageIfValid(payload, 'logo', params)

    params.set('_method', 'patch')
    return Vue.axios.post('/site/' + payload.id, params)
      .then(res => {
        Vue.auth.user().site = Site.fromJson(res.data.data)
      })
  },
  createBranch (store, payload) {
    const params = actionHelper.urlSearchParamsFromProperties(payload, { site_id: Vue.auth.user().site.id }, ['full_address'])
    return Vue.axios.post('branch', params)
  },
  getAllBranches (context, payload) {
    return new Promise((resolve, reject) => {
      const uri = Vue.auth.check() ? 'branch' : 'public/branch'
      const url = actionHelper.prepareGetAllURL(payload, uri, ['site'])
      return Vue.axios.get(url)
        .then(res => resolve(
          {
            items: Branch.fromJsonArray(res.data.data),
            pagination: res.data.meta.pagination
          }))
        .catch(err => reject(err))
    })
  },
  updateBranch (store, payload) {
    const params = actionHelper.urlSearchParamsFromProperties(payload, {}, ['full_address'])
    return Vue.axios.patch('/branch/' + payload.id, params)
  },
  likeBranch (store, payload) {
    return new Promise((resolve, reject) => {
      const params = actionHelper.urlSearchParamsFromProperties(payload)
      return Vue.axios.post('/branch/' + payload.id + '/like?include=site', params)
        .then(res => resolve(Branch.fromJson(res.data.data)))
        .catch(err => reject(err))
    })
  },
  favoriteBranch (store, payload) {
    return new Promise((resolve, reject) => {
      const params = actionHelper.urlSearchParamsFromProperties(payload)
      return Vue.axios.post('/branch/' + payload.id + '/favorite?include=site', params)
        .then(res => resolve(Branch.fromJson(res.data.data)))
        .catch(err => reject(err))
    })
  },
  deleteBranch (store, payload) {
    return Vue.axios.delete('/branch/' + payload.id)
  },
  getBranch (store, payload) {
    return new Promise((resolve, reject) => {
      return Vue.axios.get('/branch/' + payload.id + '?include=site,inspections').then(
        res => resolve(Branch.fromJson(res.data.data))
      ).catch(err => reject(err))
    })
  }
}

const setImageIfValid = (payload, image, params) => {
  if (payload[image] && typeof payload[image] !== 'string') {
    params.set(image, payload[image])
  }
}
